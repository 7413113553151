
  .rdrDateDisplayWrapper{
    background: transparent;
  }
  .rdrMonthsHorizontal{
   justify-content:space-around;
    align-items: center;
  
  }
  .rdrCalendarWrapper{
    background: transparent;
  }